
















import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
@Component({
  name: "GroupItem",
  components: {},
})
export default class extends Vue {
  @Emit("click") handleClick() {}
  @Prop({ default: "" }) private bgColor!: string;
  @Prop({ default: "" }) private rightColor!: string;
  @Prop({ default: "" }) private leftColor!: string;
  @Prop({ default: "" }) private topText!: string;
  @Prop({ default: "" }) private bottomText!: string;
  @Prop({ default: "14px" }) private topTextSize!: string;
  @Prop({ default: "10px" }) private bottomTextSize!: string;

  private doClick() {
    this.handleClick();
  }
}
